import queryString from "query-string";
import { useCallback, useEffect } from "react";
import { saveSessionId } from "../features/session/utils/saveSessionId";
import { setLoginToken } from "../features/session/utils/setLoginToken";
import { setIsInitialized, setSessionId } from "../features/session/sessionSlice";
import useLogIn from "../features/session/hooks/useLogIn";
import { useAppDispatch } from "../app/hooks";

const useInitializeMylocLoginSession = (useMylocLogin?: boolean) => {
  const dispatch = useAppDispatch();
  const { loginWithMylocToken } = useLogIn();

  const doLoginWithToken = useCallback(
    async (mylocLoginToken: string) => {
      await loginWithMylocToken({ mylocLoginToken });
    },
    [loginWithMylocToken],
  );

  const mylocAuthentication = useCallback(
    async (
      mylocLoginToken?: ReturnType<typeof queryString["parse"]>[keyof ReturnType<typeof queryString["parse"]>],
    ) => {
      if (mylocLoginToken) {
        setLoginToken(dispatch, mylocLoginToken as string, false);
        await doLoginWithToken(mylocLoginToken as string);
      }
    },
    [doLoginWithToken, dispatch],
  );

  const initializeMylocLoginSession = useCallback(async () => {
    const query = queryString.parse(window.location.search);

    //If a virtualSessionId exists, use it instead of logging in again
    if (query.virtualSessionId) {
      const sessionId = encodeURIComponent(query.virtualSessionId as string);

      saveSessionId(sessionId);
      dispatch(setSessionId(sessionId));
    } else await mylocAuthentication(query.mylocLoginToken);

    dispatch(setIsInitialized(true));
  }, [dispatch, mylocAuthentication]);

  useEffect(() => {
    if (useMylocLogin === true) initializeMylocLoginSession();
  }, [initializeMylocLoginSession, useMylocLogin]);
};

const useInit = () => {
  useInitializeMylocLoginSession(true);
};

export default useInit;
