import { useEffect, useRef } from "react";
import useFetchForm from "./useFetchForm";

const useGetForm = ({ id }: { id?: string }) => {
  const { form, loading, fetchForm } = useFetchForm({ id });
  const initiationStatus = useRef<"initiating" | "initiated">();

  useEffect(() => {
    const initiateForm = async () => {
      if (!initiationStatus.current) {
        initiationStatus.current = "initiating";
        await fetchForm();
        initiationStatus.current = "initiated";
      }
    };

    initiateForm();
  }, [fetchForm]);

  return { form, loading, refetchForm: fetchForm };
};

export default useGetForm;
