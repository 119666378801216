import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import "./language/i18n";
import "@myloc/myloc-gui/dist/styles/css-reset.css";
import "./index.scss";
import App from "./App";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://0648b99c973b44a8a362c22dedaa1fc8@o571717.ingest.sentry.io/5720257",
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,

  tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById("root"));
