import PropTypes from "prop-types";
import Snackbar, { SEVERITY } from "../Snackbar/Snackbar";
import { SEVERITY as MESSAGE_SEVERITY } from "../../../features/dialog/dataTypes";
import { selectMessage } from "../../../features/dialog/dialogSelectors";
import { useAppSelector } from "../../../app/hooks";

const SnackbarMessage = ({ onReset }: { onReset: () => void }) => {
  const message = useAppSelector(selectMessage);

  return (
    <Snackbar
      severity={message?.type === MESSAGE_SEVERITY.ERROR ? SEVERITY.ERROR : SEVERITY.SUCCESS}
      onClose={onReset}
      message={!message?.error?.message?.includes("503") ? message?.value : undefined}
    />
  );
};

SnackbarMessage.propTypes = {
  onReset: PropTypes.func.isRequired,
};

export default SnackbarMessage;
