import { AdditionalRequestState, IdObject, IdType } from "../dataTypes";

export const sessionSliceName = "session";

export interface ResponseSessionData extends IdObject {
  token?: string;
  authorizations: IdType[];
  hasToAcceptAccessMessage: boolean;
}
export interface SessionState extends AdditionalRequestState {
  sessionId?: string;
  isInitialized: boolean;
  rememberMe?: boolean;
  loginToken?: string;
}

export interface LogInRequest {
  username?: string;
  password?: string;
  token?: string;
  mylocLoginToken?: string;
  persistent?: boolean;
}
