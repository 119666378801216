import { HeaderOptions, HEADER, RestOptions } from "@myloc/myloc-utils";
import { AppDispatch } from "../../app/store";
import i18n from "../../language/i18n";
import storage from "../../utils/store/storage";
import { SESSION_ID } from "../../utils/store/storeVariables";
import { handleError } from "../../services/error/errorService";
/**
 * Pass a custom Error handler function when needed - see customErrorHandler example in accountService.resetPassword
 * @param {*} customErrorHandler
 * @returns RestOptions
 */

const defaultRestOptions = async ({
  customErrorHandler,
  dispatch,
  includeSessionId = true,
}: {
  customErrorHandler?: RestOptions["errorService"];
  dispatch: AppDispatch;
  includeSessionId?: boolean;
}) => {
  const options = new RestOptions();

  if (customErrorHandler) options.errorService = customErrorHandler;
  else options.errorService = handleError(dispatch);

  options.headerOptions = new HeaderOptions();

  if (includeSessionId) {
    const sessionId = await storage.loadItem(SESSION_ID);

    if (sessionId) options.headerOptions.setHeader(HEADER.SESSIONID, sessionId);
  }

  options.headerOptions.setHeader(HEADER.LANGUAGE, i18n.language);

  return options;
};

export default defaultRestOptions;
