import { Dictionary } from "@reduxjs/toolkit";
import { FetchedTimestamp, RequestState, UseFetchRefs } from "../dataTypes";

const mapUseFetchRefs = (state: Dictionary<FetchedTimestamp & { requestState?: RequestState }>) => {
  return Object.entries(state).reduce((result, [id, object]) => {
    result[id] = object
      ? {
          fetchedTimestamp: object.fetchedTimestamp,
          requestState: object.requestState,
        }
      : undefined;

    return result;
  }, {} as UseFetchRefs);
};

export default mapUseFetchRefs;
