import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useTranslate } from "../../../language/i18n";
import { RequireSelected } from "../../../utils/dataTypes";
import { getValidationResult } from "../../../utils/getValidationResults";
import HttpStatusCodes from "../../../utils/HttpStatusCodes";
import { OverloadedReturnType, RequestState, REQUEST_STATE } from "../../dataTypes";
import { setError } from "../../dialog/dialogSlice";
import { LogInRequest } from "../dataTypes";
import { logIn as logInAction } from "../reducers/logIn";
import { selectRememberMe, selectRequestState } from "../sessionSelectors";
import { saveSessionId } from "../utils/saveSessionId";

type LogInWithMylocTokenType = RequireSelected<Pick<LogInRequest, "mylocLoginToken" | "persistent">, "mylocLoginToken">;

let requestStateRef: RequestState | undefined;

const useLogIn = () => {
  const dispatch = useAppDispatch();
  const translate = useTranslate();

  const rememberMe = useAppSelector(selectRememberMe);

  const requestState = useAppSelector(selectRequestState);

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;
  const isError = requestState === REQUEST_STATE.REJECTED;

  if (requestStateRef === undefined) requestStateRef = requestState;

  useEffect(() => {
    requestStateRef = requestState;
  }, [requestState]);

  const validateLogIn = useCallback(
    (
      additionalValidation: (request: LogInRequest) => OverloadedReturnType<typeof getValidationResult>,
      request?: Partial<LogInRequest>,
    ) => {
      if (!request) return getValidationResult(true, translate("DATA_MISSING"), HttpStatusCodes.BAD_REQUEST);
      return additionalValidation(request);
    },
    [translate],
  );

  const validateLoginWithToken = useCallback(
    (request: Partial<LogInRequest>) => {
      if (!request.mylocLoginToken)
        return getValidationResult(true, translate("TOKEN_MISSING"), HttpStatusCodes.BAD_REQUEST);
      return getValidationResult(false);
    },
    [translate],
  );

  const logIn = useCallback(
    async (
      logInRequest: LogInRequest,
      additionalValidation: (request: LogInRequest) => OverloadedReturnType<typeof getValidationResult>,
    ) => {
      const result = validateLogIn(additionalValidation, logInRequest);

      if (result.isError) {
        dispatch(setError({ value: result.errorMessage }));
      } else {
        if (requestStateRef !== REQUEST_STATE.PENDING) {
          requestStateRef = REQUEST_STATE.PENDING;

          const payload = (await dispatch(logInAction(logInRequest))).payload;

          if (payload && "id" in payload) saveSessionId(payload.id, rememberMe);
        }
      }
    },
    [dispatch, rememberMe, validateLogIn],
  );

  const loginWithMylocToken = useCallback(
    async (logInRequest: LogInWithMylocTokenType) => await logIn(logInRequest, validateLoginWithToken),
    [logIn, validateLoginWithToken],
  );

  return { loginWithMylocToken, isUninitialized, isLoading, isSuccess, isError };
};

export default useLogIn;
