import { URL } from "@myloc/myloc-utils";

const endpoint = (url: string, params?: any, version = "form/v1") => {
  const root = () => {
    return (process.env.REACT_APP_API_DOMAIN ?? "") + process.env.REACT_APP_API_PATH;
  };

  const templates = { ...params };

  const preparedUrl = url.replace(/{([^}]+)}/g, (_, key) => templates[key]);

  const baseURI = root();
  const fullPath = `${baseURI}${version}${preparedUrl}`;
  const uri = new URL(fullPath);

  return uri;
};

export const api = {
  account: {
    authentication: () => endpoint("/authentication"),
  },
  file: {
    file: (fileId: string) => endpoint("/files/{fileId}", { fileId }),
  },
  form: {
    form: (formId: string) => endpoint("/forms/{formId}", { formId }),
  },
};
