import { useLocation, useParams } from "react-router";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { useTranslate } from "../../language/i18n";
import { useMemo } from "react";
import useGetAggregatedForm from "./useGetAggregatedForm";
import { FormData } from "@myloc/myg-forms";

const Form = () => {
  const params = useParams();
  const translate = useTranslate();
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const inputMode = searchParams.get("inputMode");
  const enableFileUpload = searchParams.get("enableFileUpload");
  const { form, isLoading, isFilesLoading } = useGetAggregatedForm({
    id: params.id,
    loadFiles: inputMode !== null ? inputMode === "true" : false,
  });

  if (isLoading || isFilesLoading) {
    return <LoadingSpinner />;
  }

  if (!form?.sequences) {
    return <>{translate("FORM_NOT_FOUND")}</>;
  }

  return (
    <FormData
      form={form}
      columns={searchParams.get("columns") ?? undefined}
      inputMode={inputMode !== null ? inputMode === "true" : undefined}
      enableFileUpload={enableFileUpload !== null ? enableFileUpload === "true" : undefined}
    />
  );
};

export default Form;
