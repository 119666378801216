import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { selectIsInitialized, selectSessionId } from "../../features/session/sessionSelectors";
import useInit from "../../utils/useInit";
import Form from "../Form/Form";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import UserFeedback from "../UserFeedback/UserFeedback";

const Site = () => {
  useInit();

  const sessionId = useAppSelector(selectSessionId);
  const sessionInitialized = useAppSelector(selectIsInitialized);

  if (!sessionInitialized) {
    return <LoadingSpinner />;
  }

  if (sessionId === undefined) {
    return <></>;
  }

  return (
    <Router>
      <Routes>
        <Route path={"form/:id"} element={<Form />} />
      </Routes>
      <UserFeedback />
    </Router>
  );
};

export default Site;
