import { useCallback, useState } from "react";
import { Method, sendRequest, Request } from "@myloc/myloc-utils";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { api } from "../../../config/settings";
import { Form, ResponseForm } from "../dataTypes";
import { useAppDispatch } from "../../../app/hooks";
import { AppDispatch } from "../../../app/store";

const getForm = async (id: string, dispatch: AppDispatch) => {
  const url = api.form.form(id);
  const request = new Request(url, Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
};

const useFetchForm = ({ id }: { id?: string }) => {
  const dispatch = useAppDispatch();
  const [form, setForm] = useState<Form>();
  const [loading, setLoading] = useState(true);

  const fetchForm = useCallback(async () => {
    if (id) {
      setLoading(true);

      const response = await getForm(id, dispatch);

      if (response.isOk()) {
        setForm(response.data as ResponseForm);
      }

      setLoading(false);
    }
  }, [id, dispatch]);

  return { form, loading, fetchForm };
};

export default useFetchForm;
