import { Response } from "@myloc/myloc-utils";
import { AppDispatch } from "../../app/store";
import { AsyncThunkRejectWithValue, RequestError } from "../dataTypes";

export const createAsyncThunkPayload = async <Returned, ThunkArg>(
  serviceRequest: (dispatch: AppDispatch, args: ThunkArg) => Promise<Response>,
  args: ThunkArg,
  dispatch: AppDispatch,
  rejectWithValue: AsyncThunkRejectWithValue,
) => {
  const response = await serviceRequest(dispatch, args);

  if (!response.isOk()) {
    return rejectWithValue({
      message: response.message,
      httpStatusCode: response.statusCode as RequestError["httpStatusCode"],
    });
  }

  return response.data as unknown as Returned;
};
