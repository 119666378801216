import { SerializedError } from "@reduxjs/toolkit";
import { ValueOf } from "../../utils/dataTypes";

export const dialogSliceName = "dialog";

export const SEVERITY = {
  INFO: 10,
  WARNING: 20,
  ERROR: 30,
} as const;

export interface Message {
  value?: string;
  error?: SerializedError;
  type: ValueOf<typeof SEVERITY>;
}

export interface DialogState {
  message?: Message;
}
