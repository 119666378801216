import { RootState } from "../../app/rootReducer";
import { sessionSliceName } from "./dataTypes";

const selectSliceState = (state: RootState) => state[sessionSliceName];

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectSessionId = (state: RootState) => selectSliceState(state).sessionId;

export const selectIsInitialized = (state: RootState) => selectSliceState(state).isInitialized;

export const selectRememberMe = (state: RootState) => selectSliceState(state).rememberMe;
