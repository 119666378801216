import { AdditionalRequestState, FetchedIdObject, IdObject } from "../dataTypes";

export const fileSliceName = "file";

export interface ResponseFile extends IdObject {
  name: string;
  url: string;
}

export interface File extends ResponseFile, AdditionalRequestState, FetchedIdObject {}
