import { FetchedTimestamp } from "../dataTypes";

const mergeFetchedTimestampRefAndStateEntities = <T extends FetchedTimestamp>(
  ref: { [id: string]: T | undefined },
  state: { [id: string]: T | undefined },
) =>
  Object.entries(state).length > 0
    ? {
        ...ref,
        ...Object.entries(state).reduce((obj, [id, object]) => {
          const object1 = ref[id];

          return object
            ? {
                ...obj,
                [id]: {
                  ...object1,
                  ...object,
                  retrievedTimestamp: Math.max(object1?.fetchedTimestamp ?? 0, object.fetchedTimestamp || 0),
                },
              }
            : obj;
        }, {} as { [id: string]: T | undefined }),
      }
    : {};

export default mergeFetchedTimestampRefAndStateEntities;
