import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sessionSliceName, SessionState } from "./dataTypes";
import { addLogInReducers } from "./reducers/logIn";

const initialState: SessionState = {
  isInitialized: false,
};

const slice = createSlice({
  name: sessionSliceName,
  initialState,
  reducers: {
    setIsInitialized(state, action: PayloadAction<boolean>) {
      state.isInitialized = action.payload;
    },
    setRememberMe(state, action: PayloadAction<boolean>) {
      state.rememberMe = action.payload;
    },
    setLoginToken(state, action: PayloadAction<string>) {
      state.loginToken = action.payload;
    },
    setSessionId(state, action: PayloadAction<string>) {
      state.sessionId = action.payload;
    },
  },
  extraReducers: builder => {
    addLogInReducers(builder);
  },
});

const { actions, reducer } = slice;

export const { setIsInitialized, setRememberMe, setLoginToken, setSessionId } = actions;

export default reducer;
