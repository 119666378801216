import { ActionReducerMapBuilder, AsyncThunkPayloadCreator, EntityState } from "@reduxjs/toolkit";
import { ValueOf } from "../utils/dataTypes";
import HttpStatusCodes from "../utils/HttpStatusCodes";

export interface RequestError {
  httpStatusCode: ValueOf<typeof HttpStatusCodes>;
  message: string;
}

export const REQUEST_STATE = {
  PENDING: "pending",
  FULFILLED: "fulfilled",
  REJECTED: "rejected",
} as const;

export type RequestState = ValueOf<typeof REQUEST_STATE>;

export interface FetchedTimestamp {
  fetchedTimestamp: number;
}

export interface FetchedIdObject extends IdObject, FetchedTimestamp {}

export interface IdObject {
  id: string;
}
export interface IdType extends IdObject {
  type: string;
}
export interface AdditionalRequestState {
  requestState?: RequestState;
  errorMessage?: string;
}

export interface EntityActionReducerBuilderInterface<T, AdditionalState = unknown>
  extends ActionReducerMapBuilder<EntityState<T> & AdditionalRequestState & AdditionalState> {}

export type AsyncThunkRejectWithValue = Parameters<
  AsyncThunkPayloadCreator<any, any, { rejectValue: RequestError }>
>["1"]["rejectWithValue"];

export interface UseFetchRef extends FetchedTimestamp {
  requestState?: RequestState;
}
export interface UseFetchRefs {
  [id: string]: UseFetchRef | undefined;
}

export type OverloadedReturnType<T> = T extends {
  (...args: any[]): infer R;
  (...args: any[]): infer R;
  (...args: any[]): infer R;
  (...args: any[]): infer R;
}
  ? R
  : T extends {
      (...args: any[]): infer R;
      (...args: any[]): infer R;
      (...args: any[]): infer R;
    }
  ? R
  : T extends { (...args: any[]): infer R; (...args: any[]): infer R }
  ? R
  : T extends (...args: any[]) => infer R
  ? R
  : never;
