export const getValidationResult: {
  (isError: false): { isError: false };
  (isError: true, errorMessage: string, statusCode: number): {
    isError: true;
    errorMessage: string;
    statusCode: number;
  };
} = (isError: boolean, errorMessage?: string, statusCode?: number) => {
  if (!isError) {
    return { isError } as any;
  }

  return { isError, errorMessage, statusCode };
};
