import { Response, RestOptions } from "@myloc/myloc-utils";
import { miniSerializeError } from "@reduxjs/toolkit";
import { AppDispatch } from "../../app/store";
import { setError } from "../../features/dialog/dialogSlice";
import { handleClientSideSignOut } from "../../features/session/utils/clientSideSignOut";
import i18n from "../../language/i18n";
import HttpStatusCodes from "../../utils/HttpStatusCodes";
import errorCodes from "./errorCodes";

export function handleError(dispatch: AppDispatch): RestOptions["errorService"] {
  return function handleError(exception, errorHandler) {
    if (errorHandler) errorHandler(exception);
    return handleErrorResponse(exception, dispatch);
  };
}

const handleErrorResponse = (exception: any, dispatch: AppDispatch) => {
  if (exception?.response?.status === HttpStatusCodes.UNAUTHORIZED) {
    if (exception.response.data === errorCodes.SESSION_INVALID.CODE) {
      handleClientSideSignOut(dispatch);
    }
  }

  const message = getErrorMessage(exception);

  dispatch(setError({ error: miniSerializeError(exception), value: message }));

  return new Response(exception?.response?.status, message, exception);
};

export function getErrorMessage(exception: any) {
  if (!exception?.response) {
    return navigator.onLine ? i18n.t("SERVER_ERROR_DESCRIPTION") : i18n.t("INTERNET_CONNECTION_ERROR_DESCRIPTION");
  } else if (
    exception?.response?.status === HttpStatusCodes.UNAUTHORIZED &&
    !exception.response?.data?.includes(i18n.t("UNAUTHORIZED").toLowerCase())
  ) {
    return navigator.onLine
      ? i18n.t("SERVER_ERROR_DESCRIPTION_REQUIRE_LOGIN")
      : i18n.t("INTERNET_CONNECTION_ERROR_DESCRIPTION");
  } else if (
    exception?.response?.status === HttpStatusCodes.INTERNAL_SERVER_ERROR &&
    !exception?.response?.data?.includes("password")
  ) {
    return i18n.t("SERVER_COULDNT_PROCESS");
  } else if (exception?.response?.data) {
    const contentType = exception.response.headers["content-type"]; //MOVE TO some util constant

    if (typeof exception.response.data === "object") {
      return exception.response.data.errorMessage.errorMessage;
    } else if (typeof exception.response.data === "string" && !contentType?.startsWith("test/html")) {
      //Move to some util constant
      return exception.response.data;
    }
  }

  return "Unknown server error";
}
