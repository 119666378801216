import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { REQUEST_STATE, UseFetchRefs } from "../../dataTypes";
import mapUseFetchRefs from "../../utils/mapUseFetchRefs";
import mergeFetchedTimestampRefAndStateEntities from "../../utils/mergeFetchedTimestampRefAndStateEntities";
import { selectFileEntities } from "../fileSelectors";
import { fetchFile } from "../reducers/fetchFile";

let refs: UseFetchRefs;

const useFetchFile = () => {
  const dispatch = useAppDispatch();

  const files = useAppSelector(selectFileEntities);

  if ((refs as UseFetchRefs | undefined) === undefined) {
    refs = mapUseFetchRefs(files);
  }

  useEffect(() => {
    refs = mergeFetchedTimestampRefAndStateEntities(refs, mapUseFetchRefs(files));
  }, [files]);

  const loadFile = useCallback(
    async (id?: string) => {
      if (id) {
        const ref = refs[id];

        if (!ref || ref.requestState !== REQUEST_STATE.PENDING) {
          refs[id] = { fetchedTimestamp: Date.now(), requestState: REQUEST_STATE.PENDING };

          await dispatch(fetchFile(id));
        }
      }
    },
    [dispatch],
  );

  return loadFile;
};

export default useFetchFile;
