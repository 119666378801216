import { FormData } from "@myloc/myg-forms";
import { useEffect, useState } from "react";
import useGetForm from "../../features/form/hooks/useGetForm";
import useLazyGetFiles from "../../features/form/hooks/useLazyGetFiles";

type Form = Parameters<typeof FormData>["0"]["form"];
type FormSequences = Form["sequences"];
type MylocFile = Extract<FormSequences[number], { file?: any }>["file"];

const getAggregatedSequences = (
  formData: Readonly<FormSequences>,
  files: { [fileId: string]: MylocFile | undefined },
) => {
  let hasNewData = false;
  const newData = [...formData];

  for (let index = 0; index < newData.length; index++) {
    const sequence = newData[index];

    if ("type" in sequence) {
      if (sequence.fileId && files[sequence.fileId]) {
        if (sequence.file !== files[sequence.fileId]) {
          newData[index] = { ...sequence, file: files[sequence.fileId] };
          hasNewData = true;
        }
      }
    } else if (sequence.sequences) {
      const newSequences = getAggregatedSequences(sequence.sequences, files);

      if (newSequences !== sequence.sequences) {
        newData[index] = { ...sequence, sequences: newSequences };
        hasNewData = true;
      }
    }
  }

  return hasNewData ? newData : (formData as FormSequences);
};

const useGetAggregatedForm = ({ id, loadFiles }: { id?: string; loadFiles: boolean }) => {
  const { form, loading, refetchForm } = useGetForm({ id });
  const { files, isLoading: isFilesLoading } = useLazyGetFiles({ form, loadIfNotInitialized: loadFiles });
  const [modifiedForm, setModifiedForm] = useState<Form | undefined>(() =>
    form ? (loadFiles ? { ...form, sequences: getAggregatedSequences(form.sequences, files) } : form) : undefined,
  );

  useEffect(() => {
    setModifiedForm(modifiedForm => {
      const currForm = modifiedForm ?? form;

      if (currForm) {
        if (loadFiles) {
          const newSequences = getAggregatedSequences(currForm.sequences, files);

          if (newSequences !== currForm.sequences) return { ...currForm, sequences: newSequences };
        }

        return currForm;
      }
    });
  }, [form, files, loadFiles]);

  return { form: modifiedForm, isLoading: loading, isFilesLoading: loadFiles && isFilesLoading, refetchForm };
};

export default useGetAggregatedForm;
